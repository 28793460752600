@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.asml-color {
  background-color: #0F238C;
}

/* Add this CSS to your stylesheet */
@media (max-width: 640px) {
  .image-wall > div:not(:first-child) {
      display: none;
  }
  .image-wall > div:first-child {
      margin-top: -8rem;
      width: 100%;
  }
  .reverse {
      margin-top: -8rem;
      margin-bottom: 2rem;
  }
  .phoneMargins {
    margin-bottom: -10rem;
  }
}
